import React from "react";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  Button,
  FlipCard,
  Hero,
  SecondaryHeader,
  SectionHeader,
} from "../components/common";

import QuestionPaper from "../assets/images/vectors/question-paper.svg";
import { getStaticSEOImage } from "../helpers";

function FaqPage({ pageContext: { general, product, license }, location }) {
  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="FAQs"
        image={getStaticSEOImage("faqs")}
        path={location?.pathname}
      />
      <section className="md:mt-24 mt-12 md:mb-40 mb-24">
        <Hero
          subTitle="FAQ"
          title={
            <>
              Frequently (Never) Asked
              <br />
              Questions & Answers
            </>
          }
          description="I know it is ridiculous. But I went through several webages who got asked (maybe?) some common questions about graphic products, accessing, using and managing them. Lets see if it helps!"
        />
      </section>
      <section className="mb-40">
        {!general ? (
          <>
            <div className="mb-8">
              <SecondaryHeader title="General Questions" />
            </div>
            <div className="max-w-3xl m-auto">
              <FlipCard title="What kind of graphic resources you make regularly?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Do your surface patterns can be repeatable (tile-able)?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Do your files backward Adobe software compatible?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="How to edit vector files?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use the design files in unlimited projects?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use the free files in my design project for commercial print?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use Apple Pay or Google Wallet?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="I need a product right now ASAP, but would like to pay later, what should I do?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="If I face any difficulties to work with the files, would you help?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="I have a question to ask, how can I contact?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
            </div>
          </>
        ) : (
          <>
            <div className="mb-8">
              <SecondaryHeader title="General Questions" />
            </div>
            <div className="max-w-3xl m-auto">
              {!!general?.attributes?.Answers?.length &&
                general.attributes.Answers.map((answer) => (
                  <FlipCard key={answer.id} title={answer.Title}>
                    {answer.Description}
                  </FlipCard>
                ))}
            </div>
          </>
        )}
      </section>
      <section className="mb-40">
        {product ? (
          <>
            <div className="mb-8">
              <SecondaryHeader title="Using Products" />
            </div>
            <div className="max-w-3xl m-auto">
              {!!product?.attributes?.Answers?.length &&
                product.attributes.Answers.map((answer) => (
                  <FlipCard key={answer.id} title={answer.Title}>
                    {answer.Description}
                  </FlipCard>
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="mb-8">
              <SecondaryHeader title="Using Products" />
            </div>
            <div className="max-w-3xl m-auto">
              <FlipCard title="What kind of graphic resources you make regularly?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Do your surface patterns can be repeatable (tile-able)?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Do your files backward Adobe software compatible?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="How to edit vector files?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use the design files in unlimited projects?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use the free files in my design project for commercial print?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use Apple Pay or Google Wallet?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="I need a product right now ASAP, but would like to pay later, what should I do?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="If I face any difficulties to work with the files, would you help?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="I have a question to ask, how can I contact?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
            </div>
          </>
        )}
      </section>
      <section className="mb-32">
        {license ? (
          <>
            <div className="mb-8">
              <SecondaryHeader title="License & Refunds" />
            </div>
            <div className="max-w-3xl m-auto">
              {!!license.attributes?.Answers?.length &&
                license.attributes.Answers.map((answer) => (
                  <FlipCard key={answer.id} title={answer.Title}>
                    {answer.Description}
                  </FlipCard>
                ))}
            </div>
          </>
        ) : (
          <>
            <div className="mb-8">
              <SecondaryHeader title="License & Refunds" />
            </div>
            <div className="max-w-3xl m-auto">
              <FlipCard title="What kind of graphic resources you make regularly?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Do your surface patterns can be repeatable (tile-able)?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Do your files backward Adobe software compatible?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="How to edit vector files?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use the design files in unlimited projects?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use the free files in my design project for commercial print?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="Can I use Apple Pay or Google Wallet?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="I need a product right now ASAP, but would like to pay later, what should I do?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="If I face any difficulties to work with the files, would you help?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
              <FlipCard title="I have a question to ask, how can I contact?">
                Creating a great looking design is hard, really hard. It
                requires years of experience. Even you know and have the
                experience, you will find tough times to add some extra elements
                which is not your type. We, as a
              </FlipCard>
            </div>
          </>
        )}
      </section>
      <section className="mb-24">
        <div>
          <SectionHeader
            art
            artVector={QuestionPaper}
            title={
              <>
                Still Have Questions?
                <br />
                We’re More Than Ready to Answer
              </>
            }
          />
        </div>
        <div className="flex items-center justify-center mt-16">
          <Button
            to="/contact"
            hasMoustache
            icon="vg-mail"
            text="Just Ask, Anything!"
          />
        </div>
      </section>
    </Layout>
  );
}

FaqPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default FaqPage;
